<template>
  <div class="pay-monthly-rent">
    <h1 class="title">{{$t('Select the method of your next monthly payments')}}</h1>
    <div class="monthly-rent mt-4">
      <div class="monthly-rent-text">
        <p class="montserrat-medium rf-blue-color mt-3 mb-1">{{$t('Select the checkbox if you want to pay monthly payments automatically from now on')}}</p>
      </div>
      <RFCheckbox class="mt-3" :label="$t('Set monthly payments to be paid automatically')" v-model="setMonthlyPaymentsToAutomatic" />
    </div>
    <div>
      <b-row>
        <b-col md="2">
          <a :href="payMonthlyRentUrl" @click="selectReservation" v-if="!loading">
            <RFButton class="mt-4" :label="$t('Insert credit card')" background="#24425B" color="#fff"/>
          </a>
          <RFLoader class="mt-4" :size="30" color="#24425B" v-if="loading"/>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFCheckbox from '@/components/forms/RFCheckbox'
import {paymentService, userService} from "@/services";
import {mapState} from "vuex";

export default {
  name: "PayMonthlyRentOptionsPage",
  components: {
    RFButton,
    RFLoader,
    RFCheckbox,
  },
  data() {
    return {
      loading: false,
      setMonthlyPaymentsToAutomatic: false,
      payMonthlyRentUrl: null
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.profileStore.reservation,
      user: state => state.userStore.user
    }),
  },
  async beforeMount() {
    if(this.reservation==null){
      const currentReservation = await userService.getReservationById(this.user.id,this.$route.params.id)
      this.$store.commit('profileStore/setReservation', currentReservation)
    }
    this.payMonthlyRentUrl = `/profile/your-roof/${this.reservation.id}/pay-monthly-rent`
  },
  beforeDestroy() {
    this.$store.commit('profileStore/setReservation', null)
  },
  methods: {
    selectReservation() {
      this.$store.commit('profileStore/setReservation', this.reservation)
    },
  },
  watch: {
    setMonthlyPaymentsToAutomatic: {
      handler() {
        if(this.setMonthlyPaymentsToAutomatic){
          this.payMonthlyRentUrl = `/profile/your-roof/${this.reservation.id}/pay-monthly-rent-automatic`
        }
        else{
          this.payMonthlyRentUrl = `/profile/your-roof/${this.reservation.id}/pay-monthly-rent`
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pay-monthly-rent {
  min-height: 80vh;
  margin-top: 30px;

  .title {
    @include font-style($montserrat, 'medium', $font-20);
    color: $dark-gray;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: .5px solid $dark-gray;
  }
  .payment-credit-card-form {
    .card-box {
      margin: 0 5px;
      padding-top: 10px;
      border: 1px solid $dark-blue;
    }
  }
  .last-payment-div {
    @include font-style($montserrat, 'medium', $font-16);
    color: $dark-blue;
  }
  .monthly-rent-text {
    @include font-style($montserrat-bold, 'bold', $font-14);
    color: var(--color);

    .mail {
      color: var(--color);
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .pay-monthly-rent {
    padding-left: 50px;
    margin-top: 0;
  }
}
</style>
